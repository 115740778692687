<template>
  <PageCard
    pageName="Features"
    :iconClass="['fa', 'fa-location-arrow', 'fa-lg']"
  >
    <template slot="page_content">
      <b-table show-empty stacked="md" :items="items" :fields="fields">
        <template v-slot:cell(state)="data">
          <c-switch
            type="text"
            variant="success"
            on="Yes"
            off="No"
            :pill="true"
            size="sm"
            :checked="data.item.state"
            @change="toggleFeature(data.item, $event)"
          />
        </template>
      </b-table>
    </template>
  </PageCard>
</template>

<script>
import PageCard from '@/modules/core/components/layouts/PageCard'
import cSwitch from '@/components/Switch'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'Features',
  components: {
    PageCard,
    cSwitch
  },
  data() {
    return {
      items: [],
      fields: [
        { key: 'name', label: 'Feature' },
        { key: 'description', label: 'Description' },
        { key: 'state', label: 'Status' }
      ]
    }
  },
  created() {
    this.items = []
  },
  computed: {
    ...mapGetters('SuperAdmin', [])
  },
  methods: {
    ...mapMutations('SuperAdmin', []),
    toggleFeature() {}
  }
}
</script>

<style lang="scss"></style>
